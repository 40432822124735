<template>
    <v-container>
        <PageHeader :title="title" :items="items" />
        
        <loading-flux :value="loading || loadingSolicitud"></loading-flux>
        
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <b-alert
                            :show="segundosMensajeActualizacion"
                            dismissible
                            :variant="mensaje.variant"
                            @dismissed="segundosMensajeActualizacion = 0"
                            @dismiss-count-down="
                                actualizarSegundosMensajeActualizacion
                            "
                        >
                            {{ mensaje.texto }}
                        </b-alert>
                        <!-- <div class="d-flex">
                            <div class="card-title col-auto me-auto">
                                Bodega {{this.usuarioBodega.codigo_bodega}}
                            </div>
                            <Menu :lugarInstalacion="lugarInstalacion" />
                        </div> -->
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <!-- <label for="bodega">Bodega Movil</label> -->
                                    <multiselect
                                        v-model="bodegaMovil"
                                        :options="bodegasFiltro"
                                        placeholder="Seleccionar Bodega"
                                        value="codigo_bodega"
                                        label="codigo_bodega"
                                        :searchable="true"
                                        :loading="bodegasFiltroCargando"
                                        open-direction="bottom"
                                        class="helo"
                                        @select="filtrarSolicitudes"
                                        @remove="eliminarFiltrarSolicitudes"
                                    ></multiselect>
                                </div>
                            </div>
                        </div>
                       
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_length"
                                    class="dataTables_length"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Mostrar&nbsp;
                                        <b-form-select
                                            class="form-select form-select-sm"
                                            v-model="perPage"
                                            size="sm"
                                            :options="pageOptions"
                                        ></b-form-select>
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_filter"
                                    class="dataTables_filter text-md-end"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Buscar:
                                        <b-form-input
                                            v-model="filter"
                                            type="search"
                                            placeholder="Buscar..."
                                            class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                v-if="renderComponent"
                                class="datatables tabla-personalizada"
                                :items="solicitudMaterialesFiltro"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                select-mode="single"
                                selectable
                                @filtered="onFiltered"
                                :busy="solicitudMaterialesCargando"
                            >
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    </div>
                                </template>
                                <template #cell(accion)="data">
                                   <i
                                            class="bx bx-detail text-primary icono-botom-tabla"
                                            @click="
                                                abrirModalDetalle(data.item)
                                            "
                                            v-b-tooltip.focus
                                            title="Ver productos de la solicitud"
                                        ></i>
                                         <i
                                            class="bx bx-list-ul text-primary icono-botom-tabla"
                                            @click="
                                                abrirModalDetalleERP(data.item)
                                            "
                                            v-b-tooltip.focus
                                            title="Ver comentarios de la solicitud y de los productos"
                                        ></i>
                                </template>

                                <!-- <template #cell(rechazada)="data" v-if="rolModuloPermiso.escritura">
                                    <div
                                        class="form-check form-switch form-switch-md form-check-danger mb-3 "
                                    >
                                        <input
                                            @click="rechazarSolicitud(data.item)"
                                            class="form-check-input"
                                            type="checkbox"
                                            :checked="data.item.rechazada"
                                            :disabled="data.item.rechazada == 1 || data.item.recepcionada == 1 || data.item.vale_consumo == 1"
                                        />
                                    </div>
                                </template> -->
                                <template #cell(recepcionada)="data" v-if="rolModuloPermiso.escritura">
                                    <div
                                        class="form-check form-switch form-switch-md form-check-success mb-3 "
                                    >
                                        <input
                                            @click="recepcionarSolicitud(data.item)"
                                            class="form-check-input"
                                            type="checkbox"
                                            :checked="data.item.recepcionada"
                                            :disabled="data.item.rechazada == 1 || data.item.recepcionada == 1 || data.item.vale_consumo == 1"
                                        />
                                    </div>
                                </template>
                                <template #cell(vale_consumo)="data" v-if="rolModuloPermiso.escritura">
                                    <div
                                        class="form-check form-switch form-switch-md form-check-success mb-3 "
                                    >
                                        <input
                                            @click="generarValeConsumoSolicitud(data.item)"
                                            class="form-check-input"
                                            type="checkbox"
                                            :checked="data.item.vale_consumo"
                                            :disabled="data.item.rechazada == 1 || data.item.recepcionada == 0 || data.item.vale_consumo == 1"
                                        />
                                    </div>
                                </template>

                                <template #cell(tipo_solicitud)="data">
                                    <span
                                        class="badge font-size-13"
                                        :class="
                                            colorTipoSolicitud(
                                                data.item.tipo_solicitud
                                            )
                                        "
                                    >
                                        {{ data.item.tipo_solicitud }}
                                    </span>
                                </template>
                                <template #cell(nombre_estado_solicitud)="data">
                                    <span
                                        class="badge font-size-13"
                                        :class="
                                            colorTipoSolicitud(
                                                data.item.nombre_estado_solicitud
                                            )
                                        "
                                    >
                                        {{ data.item.nombre_estado_solicitud }}
                                    </span>
                                </template>
                                <template #cell(tipo_bodega)="data">
                                    <span
                                        class="badge font-size-13"
                                        :class="
                                            colorTipoSolicitud(
                                                data.item.tipo_bodega
                                            )
                                        "
                                    >
                                        {{ data.item.tipo_bodega }}
                                    </span>
                                </template>
                                <template #cell(fecha_creacion)="data">
                                    {{ formatDate(data.item.fecha_creacion) }}
                                </template>
                                <template #cell(fecha_entrega)="data">
                                    {{ formatDate(data.item.fecha_entrega) }}
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                    class="dataTables_paginate paging_simple_numbers float-end"
                                >
                                    <ul
                                        class="pagination pagination-rounded mb-0"
                                    >
                                        <!-- pagination -->
                                        <b-pagination
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal
            v-model="mostrarModal"
            title="Agregar Solicitud"
            hide-footer
            size="xl"
            no-close-on-backdrop
        >
            
            <form @submit.prevent="submit">
                <div class="row">
                        <div class="mb-3">
                            <label for="producto">Producto</label>
                            <multiselect
                                v-model="$v.form.producto.$model"
                                :options="productosFiltrados"
                                placeholder="Seleccionar Producto"
                                value="Producto"
                                label="Descripción"
                                :custom-label="descripcionProducto"
                                :searchable="true"
                                :loading="productosCargando"
                                open-direction="bottom"
                                :disabled="esEditar"
                                class="helo"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.producto.$error,
                                }"
                            ></multiselect>
                            <div
                                v-if="submitted && !$v.form.producto.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
            

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="cantidadSolicitada">Cantidad</label>
                            <input
                                id="cantidadSolicitada"
                                v-model="$v.form.cantidadSolicitada.$model"
                                type="number"
                                class="form-control"
                                placeholder="Cantidad"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.cantidadSolicitada.$error,
                                }"
                            />
                            <div
                                v-if="submitted && !$v.form.cantidadSolicitada.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="fechaEntrega">Fecha Entrega</label>
                            <b-form-datepicker
                                v-model="$v.form.fechaEntrega.$model"
                                placeholder="Fecha Entrega"
                                :state="fechaValidar"
                                class="mb-2"
                            ></b-form-datepicker>

                            <div
                                v-if="submitted && !$v.form.fechaEntrega.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="comentario">Comentario</label>
                            <input
                                id="comentario"
                                v-model="$v.form.comentario.$model"
                                type="text"
                                class="form-control"
                                placeholder="Comentario"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.comentario.$error,
                                }"
                            />
                            <div
                                v-if="submitted && !$v.form.comentario.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="unidad">Unidad</label>
                            <multiselect
                                v-model="$v.form.unidad.$model"
                                :options="unidades"
                                placeholder="Seleccionar Unidad"
                                value="value"
                                label="label"
                                :searchable="true"
                                class="helo"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.unidad.$error,
                                }"
                            ></multiselect>
                            <div
                                v-if="submitted && !$v.form.unidad.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="tipoSolicitud">Tipo Solicitud</label>
                            <multiselect
                                v-model="$v.form.tipoSolicitud.$model"
                                :options="tipoSolicitudes"
                                placeholder="Seleccionar Tipo Solicitud"
                                value="value"
                                label="label"
                                :searchable="true"
                                class="helo"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.tipoSolicitud.$error,
                                }"
                            ></multiselect>
                            <div
                                v-if="submitted && !$v.form.tipoSolicitud.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div> -->
                </div>
                 <div class="text-end mb-3">
                   
                    <b-button
                        v-if="rolModuloPermiso.escritura"
                        type="submit"
                        variant="success"
                        class="ms-1"
                        @click="guardarCerrar = false"
                        >Agregar</b-button
                    >
                   
                </div>

                <div class="row">
                    <div
                        class="table-responsive table-solicitud-material-detalle"
                    >
                        <table class="table mb-0 tabla-personalizada">
                            <thead>
                                <tr>
                                    <th>Eliminar</th>
                                    <th>Codigo Producto</th>
                                    <th>Nombre Producto</th>
                                    <th>Cantidad</th>
                                    <th>Unidad</th>
                                    <th>Fecha</th>
                                    <th>Comentario</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(producto,
                                    i) in solicitudMaterial.productos"
                                    :key="i"
                                >
                                    <td v-if="rolModuloPermiso.escritura">
                                        <i
                                            class="bx bx-trash text-primary icono-botom-tabla"
                                            @click="eliminarProducto(producto)"
                                        ></i>
                                    </td>
                                    <td>{{ producto.codigo_producto }}</td>
                                    <td>{{ producto.nombre_producto }}</td>
                                    <td>{{ producto.cantidad_solicitada }}</td>
                                    <td>{{ producto.unidad }}</td>
                                    <td>{{ formatDate(producto.fecha) }}</td>
                                    <td>{{ producto.comentario }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="text-end mt-3">
                    <b-button variant="light" @click="mostrarModal = false"
                        >Cerrar</b-button
                    >
                    <b-button
                        v-if="rolModuloPermiso.escritura"
                        type="button"
                        variant="success"
                        class="ms-1"
                        @click="crearSolicitud()"
                        >Guardar y Cerrar</b-button
                    >
                </div>
            </form>
        </b-modal>

        <b-modal
            v-model="mostrarModalDetalle"
            title="Detalle Solicitud"
            hide-footer
            hide-header
            size="xl"
        >
            <SolicitudMaterialDetalle :solicitudMaterial='solicitudSeleccionada' @cerrarDetalle='cerrarDetalle'/>
            
        </b-modal>

         <b-modal
            v-model="mostrarModalDetalleERP"
            :title="`Detalle Solicitud ERP - ${solicitudSeleccionada.solicitud_correlativo != null ? `COD ${solicitudSeleccionada.solicitud_correlativo}` : '' }`"
            hide-footer
            size="xl"
        >
            <SolicitudMaterialDetalleERP
                :solicitudMaterial="solicitudSeleccionada"
                :lugarInstalacion="lugarInstalacion"
                @cerrarDetalle="cerrarDetalleERP"
            />
        </b-modal>
    </v-container>
</template>
<script>
//import Layout from "@/router/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required,numeric } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import { productoMethods, productoComputed } from "@/state/helpers";
import { lugarInstalacionMethods, lugarInstalacionComputed } from "@/state/helpers";
import {
    solicitudMaterialMethods,
    solicitudMaterialComputed,
} from "@/state/helpers";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
// import Menu from "@/components/widgets/menu/MenuWidget";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import {bodegaMethods,bodegaComputed} from "@/state/helpers";
import {usuarioBodegaMethods,usuarioBodegaComputed} from "@/state/helpers";
import SolicitudMaterialDetalle from "./solicitud-material-detalle-bodega.vue";
import SolicitudMaterialDetalleERP from "./solicitud-material-detalle-erp.vue";
import moment from "moment";
moment.locale("es");
export default {
    mixins: [validationMixin],
    page: {
        title: "Solicitud Materiales",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        //Layout,
        PageHeader,
        Multiselect,
        // Menu,
        SolicitudMaterialDetalle,
        SolicitudMaterialDetalleERP
    },

    data() {
        return {
            bodegaMovil:null,
            bodegasFiltro: [],
            bodegasFiltroCargando: false,
            bloquearControles:false,
            renderProductos: true,
            renderComponent: true,
            loading:false,
            loadingSolicitud:false,
            mensaje: { variant: "", texto: "" },
            segundos: 5,
            segundosMensajeActualizacion: 0,
            lugarInstalacion: {},
            proyectos: [],
            title: "Solicitues de materiales",
            items: [
                {
                    text: "Bodega Movil",
                    active:true
                },
                {
                    text: "Solitud Material Bodega",
                    href: `/bodegamovil/solicitudes`,
                }
            ],
            totalRows: 1,
            todoTotalRows: 1,
            currentPage: 1,
            todocurrentPage: 1,
            perPage: 10,
            todoperPage: 10,
            pageOptions: [10, 25, 50, 100],
            todoOptions: [10, 50, 100, 150, 200],
            filter: null,
            todoFilter: null,
            filterOn: [],
            todofilterOn: [],
            sortBy: "fecha_creacion",
            sortDesc: true,
            fields: [
                {
                    key: "Accion",
                    sortable: false,
                },
                {
                    key: "vale_consumo_numero",
                    label: "Número Vale Consumo",
                    sortable: true,
                },
                {
                    key: "codigo_proyecto",
                    label: "Proyecto",
                    sortable: true,
                },
                {
                    key: "codigo_lugar_instalacion",
                    label: "Lugar Instalación",
                    sortable: true,
                },
                {
                    key: "fecha_creacion",
                    sortable: true,
                },
                {
                    key: "fecha_entrega",
                    sortable: true,
                },
                 {
                    key: "codigo_bodega",
                    label: "Código Bodega",
                    sortable: true,
                },
                {
                    key: "nombre_estado_solicitud",
                    label: "Estado Solicitud",
                    sortable: true,
                },
                // {
                //     key: "rechazada",
                //     sortable: true,
                // },
            ],
            codigoProyecto: null,
            codigoLugarInstalacion: null,
            guardarCerrar: true,
            solicitudSeleccionada: {},
            solicitudMaterial: {
                codigo_lugar_instalacion: "",
                codigo_proyecto: "",
                productos: [],
            },
            solicitudMateriales: [],
            solicitudMaterialesFiltro: [],
            solicitudMaterialesDetalle: [],
            solicitudMaterialDetalles: [],
            solicitudMaterialDetallesCargando: false,
            esEditar: false,
            submitted: false,
            mostrarModal: false,
            productos: [],
            productosCargando: false,
            solicitudMaterialesCargando: false,
            unidades: [
                { value: "un", label: "un" },
                { value: "m", label: "m" },
                { value: "m3", label: "m3" },
                { value: "kg", label: "kg" },
                { value: "ton", label: "ton" },
                { value: "día", label: "día" },
            ],
            tipoSolicitudes: [
                { value: "Solicitud", label: "Solicitud" },
                { value: "Reserva", label: "Reserva" },
                { value: "Recepcion", label: "Recepcion" },
                { value: "Consumo", label: "Consumo" },
                { value: "ValeConsumo", label: "ValeConsumo" },
                { value: "Devolucion", label: "Devolucion" },
            ],
            fechaValidar: null,
            fechaTerminoValidar: null,
            form: {
                producto: null,
                cantidadSolicitada: null,
                fechaEntrega: null,
                comentario: null,
                unidad: null
            },
            mostrarModalDetalle: false,
            mostrarModalDetalleERP: false,
            tipoSolicitud: null,
            rolModuloPermiso: {},
            productosFiltrados:[],
            usuarioBodega:{}
        };
    },
    validations: {
        form: {
            producto: { required },
            cantidadSolicitada: { required,numeric },
            fechaEntrega: {},
            comentario: {},
            unidad: { required },

        },
    },
    mounted() {
        this.obtenerSolicitudMateriales()
        // this.obtenerBodegaMovil()
        this.obtenerBodegasFiltro()
        this.solicitudMaterial.codigo_lugar_instalacion = null
        this.solicitudMaterial.codigo_proyecto = null
        let permisos = permiso.obtenerPermisos(
            this.user,
            this.$router.currentRoute.name
        );
        this.rolModuloPermiso = permisos;
    },
    methods: {
        ...solicitudMaterialMethods,
        ...productoMethods,
        ...proyectoMethods,
        ...usuarioBodegaMethods,
        ...lugarInstalacionMethods,
        ...bodegaMethods,
        eliminarFiltrarSolicitudes(item){
            this.solicitudMaterialesFiltro = this.solicitudMateriales
        },
        filtrarSolicitudes(item) {
            this.solicitudMaterialesFiltro = []
            if(item.codigo_bodega == 'TODO'){
                this.solicitudMaterialesFiltro = this.solicitudMateriales
                this.obtenerSolicitudMateriales()
                return
            }else{
                this.obtenerSolicitudMaterialesUsuarioBodega(item)
            }

            // this.solicitudMaterialesFiltro.push(
            //         ...this.solicitudMateriales.filter(
            //             (val) => val.codigo_bodega == item.codigo_bodega
            //         )
            //     );
        },
        obtenerBodegasFiltro() {
            this.bodegasCargando = true;
            this.obtenerUsuarioBodegasTokenUsuario(this.user.token_usuario)
                .then((res) => {
                    this.bodegasFiltro.unshift({
                        codigo_bodega: "TODO",
                        nombre_bodega: "TODO",
                    });
                    res.body.map((item) => {
                        this.bodegasFiltro.push(item);
                    })
                    
                    this.bodegasFiltroCargando = false;
                })
                .catch((error) => {
                    this.bodegasFiltroCargando = false;
                });
        },
        cerrarDetalle(){
            this.obtenerSolicitudMateriales();
            this.mostrarModalDetalle = false
        },
         cerrarDetalleERP() {
            this.mostrarModalDetalleERP = false;
        },
        eliminarProducto(producto) {
            var filtrado = this.solicitudMaterial.productos.filter(
                (c) => c.codigo_producto != producto.codigo_producto
            );
            this.solicitudMaterial.productos = filtrado;
        },
        descripcionProducto(producto) {
            return `${producto.Producto} - (${producto.Stock}) - ${producto.Descripción}`;
        },
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },
        abrirModalNuevo(tipoSolicitud) {
            this.bloquearControles = false
            this.solicitudMaterial.productos = []
            this.tipoSolicitud = tipoSolicitud;
            this.resetForm();
            this.esEditar = false;
            this.submitted = false;
            this.mostrarModal = true;
            this.fechaValidar = null;
            
            this.obtenerStockProductosLocal();
            this.mostrarBodegaMovil = false
            this.mostrarProductos = false
        },
        abrirModalEditar(datos) {
            this.solicitudSeleccionada = datos;
            this.fechaValidar = null;
            this.resetForm();
            this.obtenerStockProductosLocal();
            this.obtenerBodegasLocal()

            this.$v.form.cantidadSolicitada.$model = datos.cantidad_solicitada;
            this.$v.form.fechaEntrega.$model = datos.fechaEntrega;
            this.$v.form.comentario.$model = datos.comentario;

            this.$v.form.unidad.$model = {
                value: datos.unidad,
                label: datos.unidad,
            };

            this.esEditar = true;
            this.submitted = false;
            this.mostrarModal = true;
        },
        abrirModalDetalle(item) {
            this.solicitudSeleccionada = item;
            this.mostrarModalDetalle = true;
        },
        abrirModalDetalleERP(item) {
            this.solicitudSeleccionada = item;
            this.mostrarModalDetalleERP = true;
        },
        obtenerBodegaMovil() {
            this.solicitudMaterialesCargando = true;
            this.obtenerUsuarioBodega(this.user.email)
                .then((res) => {
                    this.usuarioBodega = res.body;
                    
                    this.obtenerSolicitudMateriales();
                })
                .catch((error) => {
                    this.solicitudMaterialesCargando = false;
                });
        },
        obtenerStockProductosLocal() {
            this.productosCargando = true;
            this.obtenerStockProductos()
                .then((res) => {
                    this.productos = res.body;

                    this.productosFiltrados = this.productos.filter(c => c.Proyecto == 'Bodega')
                    

                    this.mostrarProductos = false;
                    this.$nextTick(() => {
                        this.mostrarProductos = true;
                    });

                    if (this.esEditar) {
                        this.$v.form.producto.$model = {
                            producto_id: this.solicitudSeleccionada.producto_id,
                            nombre_producto: this.solicitudSeleccionada
                                .nombre_producto,
                        };
                    }
                    this.productosCargando = false;
                })
                .catch((error) => {
                    this.productosCargando = false;
                });
        },
        obtenerLugarInstalacionLocal() {
            this.obtenerLugarInstalacion({ codigoProyecto:this.solicitudMateriales.codigo_proyecto, codigoLugarInstalacion: this.solicitudMateriales.codigo_lugar_instalacion })
                .then((res) => {
                    this.lugarInstalacion = res.body;
                })
                .catch((error) => {});
        },
        obtenerSolicitudMateriales() {
            this.solicitudMaterialesCargando = true;
            this.obtenerSolicitudMaterialesBodegaUsuarioMovil(this.user.token_usuario)
                .then((res) => {
                    this.solicitudMateriales = res.body;
                    this.solicitudMaterialesFiltro = this.solicitudMateriales
                    this.totalRows = this.solicitudMaterialesFiltro.length
                    this.solicitudMaterialesCargando = false;
                })
                .catch((error) => {
                    this.solicitudMaterialesCargando = false;
                });
        },
        obtenerSolicitudMaterialesUsuarioBodega(bodega) {
            this.solicitudMaterialesCargando = true;
            this.obtenerSolicitudMaterialesBodegaUsuarioBodega({token_usuario:this.user.token_usuario, codigo_bodega: bodega.codigo_bodega})
                .then((res) => {
                    this.solicitudMateriales = res.body;
                    this.solicitudMaterialesFiltro = this.solicitudMateriales
                    this.totalRows = this.solicitudMaterialesFiltro.length
                    this.solicitudMaterialesCargando = false;
                })
                .catch((error) => {
                    this.solicitudMaterialesCargando = false;
                });
        },
        obtenerSolicitudMaterialDetallesProyecto(solicitud_material_id) {
            this.solicitudMaterialDetalles = []
            this.solicitudMaterialDetallesCargando = true;
            this.obtenerSolicitudMaterialDetalles(solicitud_material_id)
                .then((res) => {
                    this.solicitudMaterialDetalles = res.body;
                    this.solicitudMaterialDetallesCargando = false;
                })
                .catch((error) => {
                    this.solicitudMaterialDetallesCargando = false;
                });
        },
        rechazarSolicitud(item) {
           
            if(item.rechazada == false){
                const mensajeEliminar = Swal.mixin({
                customClass: {
                    title: "font-size-16",
                    confirmButton: "btn btn-primary me-2",
                    cancelButton: "btn btn-light",
                },
                buttonsStyling: false,
            });
            mensajeEliminar
                .fire({
                    title: "Esta seguro que desea rechazar la solicitud?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Si, Rechazar",
                    cancelButtonText: "Cancelar",
                    allowOutsideClick: false,
                })
                .then((result) => {
                    if (result.value) {
                        this.loading = true
                        this.actualizarSolicitudMaterialRechazada({solicitud_material_id:item.solicitud_material_id})
                            .then((res) => {
                                if (res.status == 202) {
                                    this.mensaje.variant = "danger";
                                    this.mensaje.texto =
                                        "Ha ocurrido un error, intente nuevamente";
                                }

                                if (res.status == 204) {
                                    this.mensaje.variant = "success";
                                    this.mensaje.texto =
                                        "Solicitud Rechazada correctamente!!!";
                                
                                }
                                this.obtenerSolicitudMateriales();
                                this.segundosMensajeActualizacion = this.segundos;
                                this.loading = false
                            })
                            .catch((error) => {
                                this.mensaje.variant = "danger";
                                this.mensaje.texto =
                                    "Ha ocurrido un error, intente nuevamente";
                                this.obtenerSolicitudMateriales();
                                this.segundosMensajeActualizacion = this.segundos;
                                this.loading = false
                            });
                    }else{
                        this.forceRerender()
                    }
                 });
            }
        },
        recepcionarSolicitud(item) {
           
            if(item.recepcionada == false){
                const mensajeEliminar = Swal.mixin({
                customClass: {
                    title: "font-size-16",
                    confirmButton: "btn btn-primary me-2",
                    cancelButton: "btn btn-light",
                },
                buttonsStyling: false,
            });
            mensajeEliminar
                .fire({
                    title: "Esta seguro que desea recepcionar la solicitud?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Si, Recepcionar",
                    cancelButtonText: "Cancelar",
                    allowOutsideClick: false,
                })
                .then((result) => {
                    if (result.value) {
                        this.loading = true
                        this.actualizarSolicitudMaterialRecepcionada({solicitud_material_id:item.solicitud_material_id})
                            .then((res) => {
                                if (res.status == 202) {
                                    this.mensaje.variant = "danger";
                                    this.mensaje.texto =
                                        "Ha ocurrido un error, intente nuevamente";
                                }

                                if (res.status == 204) {
                                    this.mensaje.variant = "success";
                                    this.mensaje.texto =
                                        "Solicitud Recepcionada correctamente!!!";
                                
                                }
                                this.obtenerSolicitudMateriales();
                                this.segundosMensajeActualizacion = this.segundos;
                                this.loading = false
                            })
                            .catch((error) => {
                                this.mensaje.variant = "danger";
                                this.mensaje.texto =
                                    "Ha ocurrido un error, intente nuevamente";
                                this.obtenerSolicitudMateriales();
                                this.segundosMensajeActualizacion = this.segundos;
                                this.loading = false
                            });
                    }else{
                        this.forceRerender()
                    }
                 });
            }
        },
        generarValeConsumoSolicitud(item) {
           
            if(item.vale_consumo == false){
                const mensajeValeConsumo = Swal.mixin({
                customClass: {
                    title: "font-size-16",
                    confirmButton: "btn btn-primary me-2",
                    cancelButton: "btn btn-light",
                    input: 'font-size-16',
                    inputLabel: 'font-size-16',
                },
                buttonsStyling: false,
            });
            mensajeValeConsumo
                .fire({
                    title: "Esta seguro que desea generar el vale de consumo?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Si, Generar",
                    cancelButtonText: "Cancelar",
                    allowOutsideClick: false,
                    input: 'textarea',
                    inputLabel: 'Desea agregar un comentario?',
                    inputPlaceholder: 'Comentario...',
                    inputAttributes: {
                        maxlength: 500,
                    }
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.loading = true
                        this.actualizarSolicitudMaterialValeConsumo({solicitud_material_id:item.solicitud_material_id,vale_consumo_comentario:result.value})
                            .then((res) => {
                                if (res.status == 200) {
                                    this.mensaje.variant = "danger";
                                    this.mensaje.texto =
                                        "Antes de marcar esta opción, debe ingresar todos los productos como consumido (Columna Acción)";
                                }
                                if (res.status == 202) {
                                    this.mensaje.variant = "danger";
                                    this.mensaje.texto =
                                        "Ha ocurrido un error, intente nuevamente";
                                }

                                if (res.status == 204) {
                                    this.mensaje.variant = "success";
                                    this.mensaje.texto =
                                        "Vale de consumo generado correctamente!!!";
                                
                                }
                                this.obtenerSolicitudMateriales();
                                this.segundosMensajeActualizacion = this.segundos;
                                this.loading = false
                            })
                            .catch((error) => {
                                this.mensaje.variant = "danger";
                                this.mensaje.texto =
                                    "Ha ocurrido un error, intente nuevamente";
                                this.obtenerSolicitudMateriales();
                                this.segundosMensajeActualizacion = this.segundos;
                                this.loading = false
                            });
                    }else{
                        this.forceRerender()
                    }
                 });
            }
        },
        forceRerender() {
            this.renderComponent = false;
            this.$nextTick(() => {
                this.renderComponent = true;
            });
        },
        crearSolicitud() {
            this.loadingSolicitud = true
            this.solicitudMaterial.tipo_solicitud = this.tipoSolicitud;

            this.crearSolicitudMaterial(this.solicitudMaterial)
                .then((res) => {
                    if (res.status == 201) {
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Solicitud  creada correctamente!!!";
                        this.mostrarModal = false;
                        this.resetFormAdd()
                    }

                    if (res.status == 208) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto = "La solicitud ya existe!!!";
                    }

                    this.segundosMensajeActualizacion = this.segundos;
                    this.obtenerSolicitudMateriales();
                    this.solicitudMaterial.productos = [];
                    this.loadingSolicitud = false
                })
                .catch((error) => {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "Ha ocurrido un error, intente nuevamente";
                    this.segundosMensajeActualizacion = this.segundos;
                    this.loadingSolicitud = false
                });
        },
        submit(e) {
            this.submitted = true;
            this.$v.form.$touch();

            let validarFechas = false;
            if (this.$v.form.fechaEntrega.$model == null && this.tipoSolicitud == "Reserva") {
                this.fechaValidar = false;
                validarFechas = true;
            }
           
            if (this.$v.form.$invalid) {
                return;
            }
            
            if (validarFechas && this.tipoSolicitud == "Reserva") {
                return;
            }
            const solicitud_material_id = this.solicitudSeleccionada
                .solicitud_material_id;

            const codigo_proyecto = null;
            const codigo_lugar_instalacion = null;
            const codigo_producto = this.$v.form.producto.$model.Producto;
            const nombre_producto = this.$v.form.producto.$model.Descripción;
            const cantidad_solicitada = this.$v.form.cantidadSolicitada.$model;

            const comentario = this.$v.form.comentario.$model != null ? this.$v.form.comentario.$model : null;
            const unidad = this.$v.form.unidad.$model.value;
           

            this.solicitudMaterial.codigo_bodega = 'BODEGA CENTRAL'; 
            this.solicitudMaterial.nombre_bodega = 'BODEGA CENTRAL';
            this.solicitudMaterial.tipo_bodega = 'Central'
            this.solicitudMaterial.codigo_bodega_movil = this.usuarioBodega.codigo_bodega
            this.solicitudMaterial.fecha_entrega = null
     

            if (this.esEditar) {

            } else {
                let producto = {
                    codigo_proyecto,
                    codigo_lugar_instalacion,
                    codigo_producto,
                    nombre_producto,
                    cantidad_solicitada,
                    comentario,
                    unidad
                };

                this.solicitudMaterial.productos.push(producto);
                
                this.$v.form.producto.$model = null;
                this.$v.form.cantidadSolicitada.$model = null;
                this.$v.form.fechaEntrega.$model = null;
                this.$v.form.comentario.$model = null;
                this.$v.form.unidad.$model = null
            }

            this.submitted = false;
        },
        resetForm() {
            this.form = {
                producto: null,
                cantidadSolicitada: null,
                fechaEntrega: null,
                comentario: null,
                unidad: null
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        resetFormAdd() {
            this.form = {
                producto: null,
                cantidadSolicitada: null,
                fechaEntrega: null,
                comentario: null,
                unidad: null
            };
        },
        eliminarActividadProyectoLocal(item) {
            const mensajeEliminar = Swal.mixin({
                customClass: {
                    title: "font-size-18",
                    confirmButton: "btn btn-danger ms-2",
                    cancelButton: "btn btn-gris-claro",
                },
                buttonsStyling: false,
            });
            mensajeEliminar
                .fire({
                    title: "Esta seguro que desea eliminar el registro?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Eliminar",
                    cancelButtonText: "Cancelar",
                    allowOutsideClick: false,
                    reverseButtons: true
                })
                .then((result) => {
                    if (result.value) {
                        this.eliminarActividadProyecto(
                            item.solicitud_material_id
                        )
                            .then((res) => {
                                if (res.status == 202) {
                                    this.mensaje.variant = "danger";
                                    this.mensaje.texto =
                                        "Ha ocurrido un error, intente nuevamente";
                                }

                                if (res.status == 204) {
                                    this.mensaje.variant = "success";
                                    this.mensaje.texto =
                                        "Solicitud eliminada correctamente!!!";
                                    if (this.guardarCerrar) {
                                        this.mostrarModal = false;
                                    }
                                }
                                this.segundosMensajeActualizacion = this.segundos;
                                this.obtenerSolicitudMateriales();
                            })
                            .catch((error) => {
                                this.mensaje.variant = "danger";
                                this.mensaje.texto =
                                    "No se ha eliminado el registro, intente nuevamente";
                                this.mostrarModal = false;
                                this.segundosMensajeActualizacion = this.segundos;
                            });
                    }
                });
        },
        colorTipoSolicitud(item) {
            if (item == "Solicitud") {
                return "bg-success";
            } else if (item == "Reserva") {
                return "bg-primary";
            } else if (item == "Recepcionada") {
                return "bg-warning";
            } else if (item == "Consumida") {
                return "bg-info";
            } else if (item == "Vale Consumo") {
                return "bg-info";
            } else if (item == "Devolucion") {
                return "bg-danger";
            }else if (item == "Central") {
                return "bg-success";
            }else if (item == "Movil") {
                return "bg-primary";
            }else if (item == "Abierta") {
                return "bg-success";
            }else if (item == "Cerrada") {
                return "bg-danger";
            }
            
        },
        formatDate(value) {
            const duration = moment(value).format("DD/MM/yyyy");
            return `${duration}`;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        todoFiltered(filteredItems) {
            this.todoTotalRows = filteredItems.length;
            this.todocurrentPage = 1;
        },

        handleInput(value, data) {
            this.editableDataItems[data.index][data.field.key] = value;
        },
    },
    computed: {
        ...proyectoComputed,
        ...authUsuarioComputed,
    },
};
</script>

<style>
.crearActividadesProyecto {
    margin-right: 10px;
}

.table-solicitud-material-detalle {
    height: 300px;
}
</style>
